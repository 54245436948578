import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { Office } from '@models/company/office.model';

export class AddressColumn extends BadgeColumn<Office> {
    title = 'company.offices.addressColumn';
    property = 'addressLine1';
    sortable = true;
    type: CellDisplay = CellDisplay.badge;
    displayWidth = 40;
    primaryAddressId: number;

    getBadgeDisplay(office: Office): BadgeDisplay | undefined {
        if (this.primaryAddressId != office.id) {
            return;
        }
        return {
            type: 'success',
            label: 'company.offices.primaryLabel',
        };
    }

    getCellText(office: Office): string | null {
        return office.addressLine1;
    }

    setPrimaryAddressId(id: number) {
        this.primaryAddressId = id;
    }
}
