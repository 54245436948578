import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Asset } from '@app/models/company/asset.model';

export class CategoryColumn extends Column {
    title = 'table.assets.assetCategoryColumn';
    property = 'category';
    sortable = true;
    sortField = 'category.name';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(asset: Asset): string | null {
        return asset.category?.name;
    }
}
