import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Office } from '@models/company/office.model';

export class ProvinceColumn extends Column {
    title = 'company.offices.provinceColumn';
    property = 'province';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(office: Office): string {
        return office.provinceDisplayName;
    }
}
