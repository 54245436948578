import { Injectable } from '@angular/core';
import { MenuItem } from '@app/interfaces';
import { Asset } from '@app/models/company/asset.model';
import { MenuService } from '@app/services/menu/menu.service';
import { Job } from '@models/company/job.model';
import { Office } from '@models/company/office.model';

const CompanyMenu: MenuItem[] = [
    {
        key: 'tabs.overview',
        link: ['/company', 'overview'],
    },
    {
        key: 'tabs.offices',
        link: ['/company', 'offices'],
        can: Office.permission.view,
    },
    {
        key: 'tabs.positions',
        link: ['/company', 'positions'],
        can: Job.permission.view,
    },
    {
        key: 'tabs.assets',
        link: ['/company', 'assets'],
        can: [Asset.managePermission],
        children: [
            {
                key: 'tabs.log',
                link: ['/company/assets'],
            },
            {
                key: 'tabs.categories',
                link: ['/company/asset-categories'],
            },
        ],
    },
    {
        key: 'tabs.calendarFeeds',
        link: ['/company', 'calendar-feeds'],
    },
];

@Injectable()
export class CompanyMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = CompanyMenu;
    }
}
