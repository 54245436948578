import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';
import { AssetAssignment } from '@app/models/company/asset-assignment.model';

export class EmployeeNameColumn extends Column {
    title = 'table.assets.employeeColumn';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(object: AssetAssignment): string | null {
        return object.employee?.fullName;
    }
}
