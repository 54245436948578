import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import {
    AssignedToColumn,
    CategoryColumn,
    NameColumn,
    SerialNumberColumn,
} from '@company/services/tables/assets-table/columns';
import { Asset } from '@app/models/company/asset.model';

const assignOption: MenuColumnItem = {
    label: 'table.assets.assign',
    event: 'assign',
};

const reassignOption: MenuColumnItem = {
    label: 'table.assets.reassign',
    event: 'assign',
};

const editOption: MenuColumnItem = {
    label: 'table.assets.edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'table.assets.delete',
    event: 'delete',
};

@Injectable()
export class AssetsIndexTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(asset: Asset): (string | number)[] {
        return ['/company', 'assets', String(asset.id)];
    }

    getMenuColumnOptionsForRow(asset: Asset): MenuColumnItem[] {
        if (asset.assignment?.employee) {
            return [reassignOption, editOption, deleteOption];
        }
        return [assignOption, editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new NameColumn(), new CategoryColumn(), new SerialNumberColumn(), new AssignedToColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Asset.with(['assignment', 'assignment.employee', 'category']));
    }
}
