import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Asset } from '@app/models/company/asset.model';
import { Translatable } from '@app/types/translatable.type';

export class AssignedToColumn extends Column {
    title = 'table.assets.assignedToColumn';
    sortable = true;
    sortField = 'assignedTo';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(asset: Asset): string | null | Translatable {
        if (asset.assignment?.employee) {
            return asset.assignment?.employee?.fullName;
        }
        return 'table.assets.unassigned';
    }
}
