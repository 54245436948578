import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Feed } from '@company/interfaces/feed.interface';
import { AuthService } from '@app/services';
import { CalendarFeed } from '@models/company/clanedar-feed.model';

const EMPLOYEE_FEEDS_ENDPOINT = '/v2/companyManagement/companies/:company/calendarFeeds';
const TIME_OFF_FEEDS_ENDPOINT = '/v1/timeoff/calendar/feeds';

@Injectable()
export class FeedsService {
    private host: string;
    private feedDescriptions = {
        birthdays: `A feed of all employee's birthdays.`,
        anniversaries: `A feed of all employee's anniversaries.`,
        admin: `<strong>All</strong> approved time off requests with details about leave type and reasons given.`,
        manager: `<strong>Your reports'</strong> approved time off requests.`,
        employee: `<strong>All</strong> approved time off requests.`,
    };

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.host = `webcal:${environment.api.split(':')[1]}`;
    }

    getAllFeeds(): Promise<Feed[]> {
        if (!this.auth.isAdmin()) {
            return this.getTimeOffFeeds();
        }
        return Promise.all([this.getEmployeeFeeds(), this.getTimeOffFeeds()]).then(([employeeFeeds, timeOffFeeds]) => {
            return [...employeeFeeds, ...timeOffFeeds];
        });
    }

    getEmployeeFeeds(): Promise<Feed[]> {
        const endPoint = EMPLOYEE_FEEDS_ENDPOINT.replace(':company', this.auth.company.id.toString());

        return CalendarFeed.param('company', this.auth.company.id)
            .all()
            .then(([calendarFeeds]) => {
                return calendarFeeds.map((calendarFeed: CalendarFeed): Feed => {
                    return this.buildFeed(calendarFeed.specialType, endPoint, calendarFeed.token);
                });
            });
    }

    getTimeOffFeeds(): Promise<Feed[]> {
        return this.http
            .get(TIME_OFF_FEEDS_ENDPOINT)
            .toPromise()
            .then((timeOffFeeds) => {
                return Object.keys(timeOffFeeds).map((feedName: string): Feed => {
                    return this.buildFeed(feedName, TIME_OFF_FEEDS_ENDPOINT, timeOffFeeds[feedName], '- Time Off Feed');
                });
            });
    }

    private buildFeed(feedName: string, endpoint: string, slug: string, labelExtra = 'Feed'): Feed {
        const properCase = feedName.charAt(0).toUpperCase() + feedName.slice(1);
        const label = `${properCase} ${labelExtra}`;
        const description = this.feedDescriptions[feedName];
        const url = `${this.host}${endpoint}/${slug}?noCache`;
        return {
            label,
            description,
            url,
        };
    }
}
