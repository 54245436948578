import { FeedsService } from '@company/services/feeds.service';
import { CompanyMenuService } from './company-menu.service';
import { AssetAssignmentTable } from './tables/asset-assignment-history-table/asset-assignment-history.table';
import { AssetCategoriesIndexTable } from './tables/asset-categories-table/asset-categories-index.table';
import { AssetsIndexTable } from './tables/assets-table/assets-index.table';
import { OfficesIndexTable } from './tables/offices-table/offices-index.table';
import { PositionsIndexTable } from './tables/positions-table/positions-index.table';
import { SetupGuideService } from '@company/services/setup-guide.service';

const Services = [
    FeedsService,
    PositionsIndexTable,
    OfficesIndexTable,
    AssetsIndexTable,
    AssetCategoriesIndexTable,
    AssetAssignmentTable,
    CompanyMenuService,
    SetupGuideService,
];

export {
    AssetAssignmentTable,
    AssetCategoriesIndexTable,
    AssetsIndexTable,
    CompanyMenuService,
    FeedsService,
    OfficesIndexTable,
    PositionsIndexTable,
    SetupGuideService,
    Services,
};
