import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { NameColumn } from '@company/services/tables/assets-table/columns';
import { AssetCategory } from '@app/models/company/asset-category.model';

const editOption: MenuColumnItem = {
    label: 'table.assets.editCategory',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'table.assets.deleteCategory',
    event: 'delete',
};

@Injectable()
export class AssetCategoriesIndexTable extends Table {
    links = false;
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new NameColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(AssetCategory.param('company', this.auth.company.id));
    }
}
