import { AuthService } from '@app/services';
import { EmployeeNameColumn, AssignedOnColumn, ReturnedOnColumn } from './columns';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Injectable } from '@angular/core';
import { Asset } from '@app/models/company/asset.model';
import { AssetAssignment } from '@app/models/company/asset-assignment.model';

@Injectable()
export class AssetAssignmentTable extends Table {
    links = false;

    constructor(
        protected auth: AuthService,
        public asset: Asset
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new EmployeeNameColumn(), new AssignedOnColumn(), new ReturnedOnColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(AssetAssignment.param('asset', this.asset.id).with(['employee']));
    }
}
