import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class NameColumn extends Column {
    title = 'company.offices.nameColumn';
    property = 'name';
    displayWidth = 20;
    sortable = true;
    type: CellDisplay = CellDisplay.string;
}
