import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonApiResponse } from '@app/interfaces';
import { AuthService } from '@app/services';
import { CompanyResources } from '@company/company.resource';
import { SetupGuideStep } from '@models/company/setup-guide-step.model';
import { SetupGuide } from '@models/company/setup-guide.model';

interface SetupGuideInterface {
    id: number;
    companyId: number;
    moduleId: number;
    initialActionCompletedBy: number | null;
    initialActionCompletedAt: Date | null;
    finalActionCompletedBy: number | null;
    finalActionCompletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

interface SetupGuideStepInterface {
    id: number;
    setupGuideId: number;
    stepKey: string;
    completedBy: number | null;
    completedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

@Injectable({ providedIn: 'root' })
export class SetupGuideService {
    constructor(
        private readonly http: HttpClient,
        private auth: AuthService
    ) {}

    initialActionComplete(setupGuide: SetupGuide): Promise<SetupGuideInterface> {
        const url = CompanyResources.SetupGuideInitialActionComplete.replace(':setupGuideId', setupGuide.id.toString());
        return this.http
            .post(url, {})
            .toPromise()
            .then((res: JsonApiResponse<SetupGuideInterface>) => {
                return res.data.attributes;
            });
    }

    finalActionComplete(setupGuide: SetupGuide): Promise<SetupGuideInterface> {
        const url = CompanyResources.SetupGuideFinalActionComplete.replace(':setupGuideId', setupGuide.id.toString());
        return this.http
            .post(url, {})
            .toPromise()
            .then((res: JsonApiResponse<SetupGuideInterface>) => {
                return res.data.attributes;
            });
    }

    stepComplete(setupGuideStep: SetupGuideStep): Promise<SetupGuideStepInterface> {
        const url = CompanyResources.SetupGuideStepComplete.replace(':setupGuideStepId', setupGuideStep.id.toString());
        return this.http
            .post(url, {})
            .toPromise()
            .then((res: JsonApiResponse<SetupGuideStepInterface>) => {
                return res.data.attributes;
            });
    }
}
