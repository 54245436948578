import { Model } from '../core/base.model';

export class CalendarFeed extends Model {
    protected static _resource = 'companyManagement/companies/:company/calendarFeeds';
    protected static _version = 'v2';

    get specialType(): string {
        return this._attributes['specialType'];
    }

    get token(): string {
        return this._attributes['token'];
    }
}
